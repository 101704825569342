/* CSS for tibet */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

.not-for-ansatte {
  #main
    .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
  #main input[type="button"]:not(.red),
  #main input[type="submit"]:not(.submit-comment-button):not(.red),
  #main input[type="cancel"]:not(.red),
  #main
    button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red) {
    background-color: @color-contrast;
    &:focus,
    &:hover {
      background-color: @color-contrast--dark;
    }
  }

  &.header-search-collapsable {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
    .header-search-expand {
      background-color: @color-contrast !important;
      color: @color-light;
      &:focus,
      &:hover {
        color: @color-light;
        background-color: @color-contrast--dark !important;
      }
    }
  }
  #head-wrapper {
    background: @color-light url("../images/banner.png") no-repeat scroll center
      top;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio/images/head-dropdown-toggle-black.png") no-repeat scroll 90%
        53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 199px;
      .head-menu {
        right: 110px;
        .language {
          display: none;
        }
        a {
          color: @color-dark;
          background-color: @color-light;
          margin-top: -5px;
          padding: 6px 30px 6px 15px;
          border-radius: 3px;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header a {
        color: #ffffff;
        font-family: georgia;
        font-size: 24px;
        font-size: 2.4rem;
        font-weight: bold;
        left: 0px;
        padding: 2px 0 12px 14px;
        position: absolute;
        top: 165px;
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-dark;
      &:hover,
      &:focus {
        background: @color-primary--dark none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child {
    border-bottom: 1px @color-neutral--air solid;
    a {
      color: @color-link;
      background-color: @color-secondary;
      border-top: none !important;
      background: rgba(0, 0, 0, 0) url("../images/li-arrow.png") no-repeat
        scroll 10px 23px;
    }
  }
  a {
    &.vrtx-marked {
      background-color: @color-contrast !important;
      color: @color-light;
      border-top: none !important;
    }
    &:hover,
    &:focus {
      background-color: @color-neutral--light;
    }
  }
  span::before,
  a::before {
    content: " " !important;
  }
}

table th {
  font-weight: bold;
}

#vrtx-additional-content .vrtx-frontpage-box {
  border-bottom: 4px solid #eaeaea;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-blue.svg") no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-blue.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-blue.svg");
  }
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-secondary;
    *,
    a {
      color: @color-light;
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: @color-light url("../images/banner.png") no-repeat scroll left
        top;
      #head {
        height: 178px;
        #header a {
          font-size: 20px;
          font-size: 2rem;
          line-height: 25px;
          top: 100px;
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  .menu-search {
    input[type="text"] {
      border-right-width: 7em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #vrtx-additional-content .vrtx-frontpage-box {
    border-bottom: none;
  }
}

/* Mixin */
.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #c6b597;
@color-primary--dark: #ab998f;
@color-secondary: #59423a;
@color-secondary--dark: darken(spin(@color-secondary, -5), 10%);
@color-contrast: #3e596a;
@color-contrast--dark: #0c2b3f;
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral--light: #eaedee;
@color-neutral--air: #e5e5e5;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@footer-color: @color-neutral--light;
